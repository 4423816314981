import { render, staticRenderFns } from "./SkillsIndex.vue?vue&type=template&id=5ed52ea4&scoped=true&"
import script from "./SkillsIndex.vue?vue&type=script&lang=js&"
export * from "./SkillsIndex.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5ed52ea4",
  null
  
)

export default component.exports